import {CSSProperties} from 'react';
import {
	AbsoluteFill,
	interpolate,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

const Rect: React.FC<{style?: CSSProperties}> = ({style}) => {
	return (
		<div
			style={{
				borderRadius: '2rem',
				border: '1px solid white',
				filter: 'blur(3px)',
				...style,
			}}
		/>
	);
};

const Fill: React.FC<{style?: CSSProperties}> = ({style}) => {
	return (
		<div
			style={{
				backgroundColor: '#3f4494',
				borderRadius: '2rem',
				filter: 'blur(4px)',
				...style,
			}}
		/>
	);
};
export const Background = () => {
	const frame = useCurrentFrame();
	const {durationInFrames} = useVideoConfig();

	const squareUp = interpolate(frame, [0, durationInFrames], [120, 20]);
	const rectangleToLeft = interpolate(
		frame,
		[0, durationInFrames],
		[-200, -20],
	);
	const rectangleToRight = interpolate(
		frame,
		[0, durationInFrames],
		[-100, -60],
	);
	const enlargingPill = interpolate(frame, [0, durationInFrames], [120, 320]);

	return (
		<AbsoluteFill>
			<Fill
				style={{
					position: 'absolute',
					top: 160,
					left: 24,
					width: enlargingPill,
					height: 48,
					filter: 'blur(3px)',
				}}
			/>
			<div
				style={{
					display: 'flex',
					position: 'absolute',
					right: 30,
					top: 200,
					gap: 20,
					filter: 'blur(3px)',
				}}
			>
				<Fill
					style={{
						width: 50,
						height: 160,
					}}
				/>
				<Fill
					style={{
						width: 50,
						height: 160,
					}}
				/>
				<Fill
					style={{
						width: 50,
						height: 160,
					}}
				/>
			</div>
			<Rect
				style={{
					position: 'absolute',
					right: rectangleToLeft,
					top: 30,
					width: 300,
					height: 150,
				}}
			/>
			<Rect
				style={{
					position: 'absolute',
					left: rectangleToRight,
					bottom: 240,
					width: 300,
					height: 200,
				}}
			/>
			<Rect
				style={{
					position: 'absolute',
					right: 120,
					top: squareUp,
					width: 200,
					height: 200,
				}}
			/>
		</AbsoluteFill>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

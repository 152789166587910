import React from 'react';
import {Sequence} from 'remotion';

import {FadeIn} from '../../../design/animations/FadeIn';
import {Text} from '../../../design/atoms/Text';
import {AvatarWithCaption} from '../../../design/molecules/AvatarWithCaption';
import {Speaker as SpeakerType} from '../../../types/defaultProps.types';

const Speaker: React.FC<{speaker: SpeakerType; index: number}> = ({
	speaker: {name, picture},
	index,
}) => {
	return (
		<>
			<AvatarWithCaption
				avatarPictureUrl={picture}
				avatarStyle={{
					borderRadius: '30px',
					height: 150,
					width: 150,
					border: '4px solid white',
					boxShadow: '4px 5px 0 #e95900',
				}}
				style={{
					gap: 64,
					flexDirection: 'row',
					alignItems: 'flex-start',
					padding: '0 42px',
					position: 'absolute',
					top: index * 100 - 10,
					left: index * 90,
					width: 700,
				}}
			>
				<Text
					style={{
						fontWeight: 600,
						textShadow: '2px 2px 2px #e95900',
						fontSize: '2rem',
						marginTop: 0,
						textAlign: 'left',
						paddingLeft: 0,
					}}
				>
					{name}
					<span
						style={{
							color: '#e95900',
							paddingLeft: 5,
						}}
					>
						.
					</span>
				</Text>
			</AvatarWithCaption>
		</>
	);
};

export const Speakers: React.FC<{speakers: SpeakerType[]}> = ({speakers}) => {
	return (
		<div style={{position: 'relative', top: 280 - 40 * speakers.length}}>
			{speakers.map((speaker, index) => (
				<Sequence from={index * 20} name={speaker.name} key={speaker.name}>
					<FadeIn>
						<Speaker key={speaker.name} speaker={speaker} index={index} />
					</FadeIn>
				</Sequence>
			))}
		</div>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

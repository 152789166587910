import {useEffect, useState} from 'react';
import {loadFont} from '@remotion/google-fonts/AnonymousPro';
import {interpolate, useCurrentFrame} from 'remotion';

const {fontFamily} = loadFont();

const Details: React.FC<{date: string}> = ({date}) => {
	const frame = useCurrentFrame();
	const [text, setText] = useState<string[]>([]);
	const tagStyle = {margin: 2, color: '#08fe79'};
	const detailsText = `Mettez vos talents en CSS à l'épreuve lors de la première édition Lyonnaise de Code In The Dark ! Rejoignez-nous le ${date} pour une soirée de compétition et de fun !`;
	const detailsStyleText = [
		'infos {',
		'organisateurs: LyonJS;',
		'lieu: Zenika;',
		'date: 20 juin 2023;',
		'heure: 19h00;',
	];
	const styleTag = ['<', 's', 't', 'y', 'l', 'e', '>'];
	const styleClosingTag = ['<', '/', 's', 't', 'y', 'l', 'e', '>'];

	const animateLetters = (textArray: string[], delay?: number) => {
		return textArray.map((letter, index) => {
			const managedDelay = delay ? delay + index : index;
			const letterAppear = interpolate(frame - managedDelay, [0, 1], [0, 1], {
				extrapolateRight: 'clamp',
				extrapolateLeft: 'clamp',
			});
			return (
				<span key={index} style={{opacity: letterAppear}}>
					{letter}
				</span>
			);
		});
	};

	useEffect(() => {
		const splitedText = detailsText.split('');
		setText(splitedText);
	}, [detailsText]);

	return (
		<div
			style={{
				fontFamily,
				fontSize: '2rem',
				position: 'relative',
				padding: 20,
				maxWidth: '70%',
			}}
		>
			<p style={tagStyle}>&lt;div&gt;</p>
			<p style={{margin: 0, paddingLeft: 40, lineHeight: 1.5, color: 'white'}}>
				{animateLetters(text)}
			</p>
			<p style={tagStyle}>&lt;/div&gt;</p>
			<br />

			<p style={tagStyle}>{animateLetters(styleTag, 135)}</p>
			<div style={{margin: 0, paddingLeft: 40, lineHeight: 1.2}}>
				{detailsStyleText.map((line, index) => {
					const splitedLine = line.split('');

					return (
						<p
							key={index}
							style={{
								color: 'white',
								paddingLeft: index !== 0 ? 30 : 0,
								margin: 10,
							}}
						>
							{animateLetters(splitedLine, 140 + index * 12)}
						</p>
					);
				})}
				<p style={{color: 'white', margin: 10}}>{animateLetters(['}'], 195)}</p>
			</div>
			<p style={tagStyle}>{animateLetters(styleClosingTag, 196)}</p>
		</div>
	);
};

export default Details;

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

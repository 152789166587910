import {AbsoluteFill, interpolate, useCurrentFrame} from 'remotion';

export type ThemeProps = {
	themeName: string;
};

export const Theme: React.FC<ThemeProps> = ({themeName}) => {
	let themeColor;

	switch (themeName) {
		case 'Plénière':
			themeColor = '#fd5945';
			break;
		case 'Lang. & Frameworks':
			themeColor = '#dba243';
			break;
		case 'BigData & AI':
			themeColor = '#89fa98';
			break;
		case 'Web & Mobile':
			themeColor = '#33bab5';
			break;
		case 'Archi, Perf et Sécu':
			themeColor = '#4b78cb';
			break;
		case 'DevOps & Cloud':
			themeColor = '#ffe5a8';
			break;
		case 'UX/UI':
			themeColor = '#fe94a4';
			break;
		case 'Découverte':
			themeColor = '#af7e66';
			break;
		default:
			themeColor = '#6FC660';
			break;
	}

	const frame = useCurrentFrame();
	const opacity = interpolate(frame, [20, 30], [0, 1], {
		extrapolateLeft: 'clamp',
	});

	return (
		<AbsoluteFill style={{width: 'max-content', left: 'auto', right: '40px'}}>
			<div
				style={{
					position: 'relative',
					width: 180,
					height: 120,
					background: 'white',
					clipPath: 'polygon(0 0, 100% 0, 100% 100%, 50% 80%, 0 100%)',
					opacity,
				}}
			>
				<div
					style={{
						position: 'absolute',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						width: '100%',
						height: '80%',
					}}
				>
					<span
						style={{
							display: 'block',
							width: '80%',
							textAlign: 'center',
							background: themeColor,
							padding: '10px 15px',
							fontWeight: 'bold',
						}}
					>
						{themeName}
					</span>
				</div>
			</div>
		</AbsoluteFill>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

import {
	Img,
	spring,
	staticFile,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';

import {Text} from '../../../../design/atoms/Text';

export type speakerProps = {
	speakers: {name: string; picture: string; company?: string; role?: string}[];
};

export const Speakers: React.FC<speakerProps> = ({speakers}) => {
	const frame = useCurrentFrame();
	const {fps} = useVideoConfig();

	const leftLavaFall = spring({
		frame: frame - 15,
		fps,
		from: -465,
		to: -100,
	});

	const centerLavaFall = spring({frame: frame - 20, fps, from: -390, to: -100});

	const rightLavaFall = spring({frame: frame - 10, fps, from: -480, to: -100});

	const SpeakerLavaStyle = [
		{
			transform: `translateY(${leftLavaFall}px)`,
			height: 455,
			backgroundColor: '#FF6700',
			clipPath:
				'polygon(0 0, 100% 0, 100% 95%, 80% 95%, 80% 93%, 60% 93%, 60% 100%, 40% 100%, 40% 96%, 20% 96%, 20% 90%, 0 90%)',
		},
		{
			transform: `translateY(${centerLavaFall}px)`,
			height: 390,
			backgroundColor: '#E92700',
			clipPath:
				'polygon(0 0, 100% 0, 100% 85%, 80% 85%, 80% 88%, 60% 88%, 60% 93%, 40% 93%, 40% 100%, 20% 100%, 20% 90%, 0 90%)',
		},
		{
			transform: `translateY(${rightLavaFall}px)`,
			height: 440,
			backgroundColor: '#EB3D1A',
			clipPath:
				'polygon(0 0, 100% 0, 100% 100%, 80% 100%, 80% 95%, 60% 95%, 60% 93%, 40% 93%, 40% 90%, 20% 90%, 20% 94%, 0 94%)',
		},
	];

	return (
		<div
			style={{
				width: '100%',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				gap: 50,
				padding: '0 230px',
			}}
		>
			{speakers.map((speaker, index) => {
				return (
					<div
						key={index}
						style={{
							transformOrigin: 'top center',
							width: 220,
							display: 'flex',
							flexDirection: 'column',
							justifyContent: 'flex-end',
							alignItems: 'center',
							gap: 10,
							...SpeakerLavaStyle[index],
						}}
					>
						<div
							style={{
								position: 'relative',
							}}
						>
							{speaker.company && (
								<span
									style={{
										position: 'absolute',
										width: 40,
										height: 40,
										top: -15,
										right: -15,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										background: 'white',
										padding: 5,
									}}
								>
									<Img
										src={speaker.company}
										alt={`${speaker.name}'s Company`}
										style={{
											width: '100%',
											height: 'auto',
										}}
									/>
								</span>
							)}
							<Img
								src={
									speaker.picture ||
									staticFile('images/common/defaultAvatar.svg')
								}
								alt={`Picture of ${speaker.name}`}
								width={130}
								height={130}
								style={{}}
							/>
						</div>
						<Text
							style={{
								display: 'block',
								width: '100%',
								padding: 10,
								paddingBottom: 70,
								fontFamily: 'inherit',
								fontSize: 20,
								fontWeight: 'bold',
							}}
						>
							{speaker.name}
						</Text>
					</div>
				);
			})}
		</div>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

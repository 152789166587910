import {Lottie} from '@remotion/lottie';
import {
	AbsoluteFill,
	Audio,
	interpolate,
	Sequence,
	spring,
	staticFile,
	useCurrentFrame,
	useVideoConfig,
} from 'remotion';
import {z} from 'zod';

import {FadeIn} from '../../../../design/animations/FadeIn';
import {BackgroundCircleNoise} from '../../../../design/atoms/BackgroundCircleNoise';
import {useLottie} from '../../../../hooks/useLottie';
import {SpotlightNewSponsorSchema} from '../sponsors.types';

import {LogosSponsoring} from './LogosSponsoring';
import {NewSponsorTitle} from './NewSponsorTitle';
import {Spotlight} from './Spotlight';

export const SpotlightNewSponsor: React.FC<
	z.infer<typeof SpotlightNewSponsorSchema>
> = ({logo, sponsorLogo}) => {
	const {fps, width} = useVideoConfig();
	const frame = useCurrentFrame();

	const illustrationHour = useLottie('lf20_tkeaajkc');

	if (!illustrationHour) {
		return null;
	}

	const scaleUp = spring({
		frame: frame - 460,
		from: 0,
		to: 1,
		fps,
		durationInFrames: 60,
	});

	const fadeOut = interpolate(frame, [650, 740], [1, 0], {
		extrapolateRight: 'clamp',
	});

	return (
		<AbsoluteFill
			style={{
				backgroundColor: '#0c0c0d',
				overflow: 'hidden',
			}}
		>
			<Audio
				src={staticFile('sounds/tip_stevens_miracle.mp3')}
				startFrom={1900}
				volume={(frame) =>
					interpolate(frame, [0, 80], [0, 0.4], {extrapolateLeft: 'clamp'})
				}
			/>
			<Sequence from={10} durationInFrames={300} name="Texte">
				<NewSponsorTitle />
			</Sequence>
			<Sequence from={80} durationInFrames={250} name="Confettis">
				<Lottie
					style={{
						width,
						zIndex: 1,
						position: 'absolute',
						bottom: -400,
						filter: 'none',
						objectFit: 'cover',
					}}
					animationData={illustrationHour}
				/>
			</Sequence>
			<Sequence from={295} durationInFrames={300} name="Whoosh">
				<Audio src={staticFile('sounds/whoosh.mp3')} volume={0.8} />
			</Sequence>
			<Sequence from={250} durationInFrames={300} name="Spotlight">
				<Spotlight sponsorLogo={sponsorLogo} />
			</Sequence>
			<div
				style={{
					opacity: fadeOut,
				}}
			>
				<Sequence from={420} name="Noise" durationInFrames={330}>
					<FadeIn duration={50}>
						<BackgroundCircleNoise
							speed={0.004}
							circleRadius={5}
							maxOffset={50}
						/>
					</FadeIn>
				</Sequence>
				<Sequence from={420} durationInFrames={300} name="Logos">
					<LogosSponsoring
						logo={logo}
						sponsorLogo={sponsorLogo}
						scaleUp={scaleUp}
					/>
				</Sequence>
			</div>
		</AbsoluteFill>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }

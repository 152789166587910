import {Icon} from '@iconify/react';
import {DateTime} from 'luxon';
import {spring, useCurrentFrame, useVideoConfig} from 'remotion';

import {Text} from '../../../../design/atoms/Text';

type BrandedDetailsProps = {
	startingDateTime: Date | string;
	location?: string;
};

export const BrandedDetails = ({
	startingDateTime,
	location,
}: BrandedDetailsProps) => {
	const frame = useCurrentFrame();
	const {fps} = useVideoConfig();

	const inputDate: string =
		startingDateTime instanceof Date
			? startingDateTime.toISOString()
			: startingDateTime;
	const luxonDate = DateTime.fromISO(inputDate, {
		setZone: !(startingDateTime instanceof Date),
	}).setLocale('fr');

	const startingDate = luxonDate.toFormat('dd MMMM yyyy');
	const startingTime = luxonDate.toFormat("HH 'h' mm");

	const slideIn = spring({
		frame,
		fps,
		from: -100,
		to: 50,
		durationInFrames: 30,
	});

	return (
		<div
			style={{
				position: 'absolute',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'flex-start',
				height: 'max-content',
				width: location ? '50%' : '100%',
				bottom: slideIn,
			}}
		>
			<Icon
				icon="mdi:calendar"
				style={{
					fontSize: '4.5rem',
					color: 'white',
				}}
			/>
			<div>
				<Text
					style={{
						position: 'relative',
						fontFamily: 'inherit',
						display: 'block',
						textAlign: 'left',
						fontSize: '1.7rem',
						fontWeight: 'bold',
						paddingBottom: '5px',
					}}
				>
					{startingDate}
				</Text>
				<Text
					style={{
						position: 'relative',
						fontFamily: 'inherit',
						display: 'block',
						textAlign: 'left',
						fontSize: '1.18rem',
						fontWeight: 'bold',
					}}
				>
					{startingTime}
				</Text>
			</div>
		</div>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
